input:invalid {
    outline-color: #f00;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    100% {
        opacity: .25;
    }
}

.slide-up {
    animation: slide-up .5s cubic-bezier(.75, 0, .25, 1);
    /* animation-fill-mode: backwards; */
}

.slide-down {
    animation: slide-down .5s cubic-bezier(.75, 0, .25, 1) 2s;
    /* animation-direction: reverse; */
    animation-fill-mode: both;
}

.pulse {
    animation: pulse .5s ease-in 0s infinite alternate;
}

.grayscale {
    filter: grayscale(1);
}

.gimmee-green {
    background-image: linear-gradient(135deg, #43E97B 36.48%, #38F9D7 100%);
}

nav a {
    box-shadow: inset #fff 0 0em;
    transition: box-shadow 500ms cubic-bezier(0.75, 0, 0.25, 1);
}

nav .active {
    box-shadow: inset #fff 0 -0.25em;
}

::backdrop {
    background: rgba(0,0,0,.5);
}